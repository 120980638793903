import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Callback from "./components/Callback";
import Login from "./components/Login";
import { useZitadel } from "./utils/hooks/useZitadel";

function App() {
  const zitadel = useZitadel();

  const [authenticated, setAuthenticated] = useState<boolean | null>(null);

  useEffect(() => {
    zitadel.userManager.getUser().then((user) => {
      if (user) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
    });
  }, [zitadel]);

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  return (
    <div className="App">
      <header className="App-header">
        <p>Welcome to ZITADEL React</p>

        <BrowserRouter>
          <SentryRoutes>
            <Route
              path="/"
              element={
                <Login
                  authenticated={authenticated}
                  handleLogin={zitadel.login}
                />
              }
            />
            <Route
              path="/callback"
              element={
                <Callback
                  authenticated={authenticated}
                  setAuth={setAuthenticated}
                  handleLogout={zitadel.signout}
                  userManager={zitadel.userManager}
                />
              }
            />
          </SentryRoutes>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;
