//import { useLogout } from "@store/auth.store";
import { createZitadelAuth, ZitadelConfig } from "@zitadel/react";

const config: ZitadelConfig = {
  authority: import.meta.env.VITE_SSO_AUTHORITY,
  client_id: import.meta.env.VITE_SSO_CLIENT_ID,
  redirect_uri: import.meta.env.VITE_SSO_REDIRECT_URI,
  scope: "openid email profile offline_access",
};

export const zitadel = createZitadelAuth(config);

export const useZitadel = () => {
  const userManager = zitadel.userManager;
  //const logout = useLogout();

  const isTokenValid = () =>
    zitadel.userManager.getUser().then((x) => !x?.expired);
  const login = () => zitadel.userManager.signinRedirect();
  const register = () =>
    zitadel.userManager.signinRedirect({ prompt: "create" });

  const signout = () => {
    zitadel.signout();
    //logout();
  };

  return { login, signout, userManager, register, isTokenValid };
};
